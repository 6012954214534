import type { DateInput } from '@/types/global';
import {
  format,
  formatISO,
  parse,
  formatDistanceStrict,
  type Locale,
} from 'date-fns';
import { useUserStore } from '@/store/user';
import { cs } from 'date-fns/locale/cs';
import { enUS } from 'date-fns/locale/en-US';
import { de } from 'date-fns/locale/de';
import { sk } from 'date-fns/locale/sk';
import { ru } from 'date-fns/locale/ru';

const locales: Record<string, Locale> = {
  cs,
  en: enUS,
  de,
  sk,
  ru,
};

export const useDateUtils = () => {
  const { dateFormat, dateTimeFormat, timeFormat, language } = useUserStore();

  const _format = (value: string | Date, formatToken: string) => {
    return format(value, formatToken);
  };

  const formatToISODate = (value: DateInput) => {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      value = parse(value, dateFormat, new Date());
    }

    return formatISO(value, { representation: 'date' });
  };

  const formatToISODateTime = (value: DateInput) => {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      value = parse(value, dateTimeFormat, new Date());
    }

    return formatISO(value);
  };

  const formatDate = (value: DateInput) => {
    if (!value) {
      return '';
    }

    return _format(value, dateFormat);
  };

  const formatDateTime = (value: DateInput) => {
    if (!value) {
      return '';
    }

    return _format(value, dateTimeFormat);
  };

  const formatTime = (value: DateInput) => {
    if (!value) {
      return '';
    }

    return _format(value, timeFormat);
  };

  const formatDistance = (start: DateInput, end: DateInput) => {
    if (!start || !end) {
      return '';
    }

    return formatDistanceStrict(end, start, { locale: locales[language] });
  };

  return {
    formatDate,
    formatDateTime,
    formatTime,
    formatToISODate,
    formatToISODateTime,
    formatDistance,
  };
};

import { isValid, parse } from 'date-fns';
import type { DateInput } from '@/types/global';
import { useDateUtils } from '@/composables/useDateUtils';
import { useUserStore } from '@/store/user';
import { isSameOrAfterDay, isSameOrBeforeDay } from '@/utils/datetime';

export const useRule = () => {
  const { t } = useI18n();
  const { formatDate } = useDateUtils();
  const { dateFormat, dateTimeFormat } = useUserStore();

  const _message = (key: string, params?: Record<string, unknown>) =>
    params ? t(`error.${key}`, params) : t(`error.${key}`);

  const requiredValidator = (value: unknown) => {
    if (Array.isArray(value)) {
      return !!value.length || _message('required');
    }
    if (typeof value === 'string') {
      return !!value.length || _message('required');
    }
    return (value !== null && value !== undefined) || _message('required');
  };

  const isSameOrBeforeDayRule = (input: DateInput, comparing: DateInput) => {
    return (
      isSameOrBeforeDay(input, comparing) ||
      _message('dateMustBeSameOrBefore__date', {
        date: formatDate(comparing),
      })
    );
  };

  const isSameOrAfterDayRule = (input: DateInput, comparing: DateInput) => {
    return (
      isSameOrAfterDay(input, comparing) ||
      _message('dateMustBeSameOrAfter__date', {
        date: formatDate(comparing),
      })
    );
  };

  const emailValidator = (value: string) => {
    if (!value) {
      return true;
    }

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || _message('emailInvalid');
  };

  const createLengthValidator =
    (length: number, key: string) => (value: string | undefined) =>
      value ? value.length < length || _message(key) : true;

  const timeSpanValidator = (value: string) => {
    if (!value) {
      return true;
    }

    return /^\d\d:\d\d$/.test(value) || _message('timeSpanInvalid');
  };

  const phoneNumberValidator = (value: string) => {
    if (!value) {
      return true;
    }

    return (
      /^\+?(\d[\d-\s()]{7,}\d)$/.test(value) || _message('phoneNumberInvalid')
    );
  };

  const dateValidator = (value: string) => {
    if (!value) {
      return true;
    }

    const date = parse(value, dateFormat, new Date());

    return isValid(date) || _message('dateInvalid');
  };

  const dateTimeValidator = (value: string) => {
    if (!value) {
      return true;
    }

    const date = parse(value, dateTimeFormat, new Date());

    return isValid(date) || _message('dateTimeInvalid');
  };

  return {
    isSameOrBeforeDayRule,
    isSameOrAfterDayRule,
    requiredValidator,
    emailValidator,
    createLengthValidator,
    timeSpanValidator,
    phoneNumberValidator,
    dateValidator,
    dateTimeValidator,
  };
};

import type { DateInput } from '@/types/global';
import { isAfter, isBefore, isSameDay, isValid, isEqual } from 'date-fns';

export const getDateForRelative = (input: DateInput, comparing: DateInput) => {
  if (!comparing || !input) {
    return true;
  }

  const comparingDate = new Date(comparing);
  const inputDate = new Date(input);
  if (!isValid(comparingDate) || !isValid(inputDate)) {
    console.error(
      `ComparingDate ${comparingDate} or inputDate ${inputDate} is invalid!`,
    );
  }

  return { comparingDate, inputDate };
};

export const isSameOrBefore = (input: DateInput, comparing: DateInput) => {
  const relativeDates = getDateForRelative(input, comparing);
  if (relativeDates === true) {
    return relativeDates;
  }

  const { comparingDate, inputDate } = relativeDates;
  return (
    isEqual(inputDate, comparingDate) || isBefore(inputDate, comparingDate)
  );
};

export const isSameOrAfter = (input: DateInput, comparing: DateInput) => {
  const relativeDates = getDateForRelative(input, comparing);
  if (relativeDates === true) {
    return relativeDates;
  }

  const { comparingDate, inputDate } = relativeDates;
  return isEqual(inputDate, comparingDate) || isAfter(inputDate, comparingDate);
};

export const isSameOrBeforeDay = (input: DateInput, comparing: DateInput) => {
  const relativeDates = getDateForRelative(input, comparing);
  if (relativeDates === true) {
    return relativeDates;
  }

  const { comparingDate, inputDate } = relativeDates;
  return (
    isSameDay(inputDate, comparingDate) || isBefore(inputDate, comparingDate)
  );
};

export const isSameOrAfterDay = (input: DateInput, comparing: DateInput) => {
  const relativeDates = getDateForRelative(input, comparing);
  if (relativeDates === true) {
    return relativeDates;
  }

  const { comparingDate, inputDate } = relativeDates;
  return (
    isSameDay(inputDate, comparingDate) || isAfter(inputDate, comparingDate)
  );
};
